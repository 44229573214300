import classNames from "classnames"
import React from "react"

import Typography from "../../elements/V2/Typography"
import { Color } from "../../../constants/V2/color"

import { getBackgroundColorClass } from "@utils/V2/color"

type Props = {
  image: string
  description: string
  name: string
  textColor: Color
  backgroundColor: "transparent" | Color.Charcoal | Color.White
}

const TestimonialCard: React.FC<Props> = ({
  image,
  description,
  name,
  textColor,
  backgroundColor,
}) => {
  const backgoundColorClass =
    backgroundColor == "transparent"
      ? "bg-gradient-to-bl from-white-v2/5 to-white-v2/[0.01] border-2 border-white-v2/10"
      : getBackgroundColorClass(backgroundColor)

  return (
    <div
      className={classNames(
        "shadow-[0_20px_20px_0px_rgba(0,0,0,0.1)] rounded-20-v2 flex flex-col gap-20-v2 p-32-v2 w-[375px] text-left",
        backgoundColorClass
      )}
    >
      <div>
        <img src={image} className="h-12" />
      </div>
      <Typography
        text={description}
        size="body-md"
        weight="book"
        font="grotesk"
        color={textColor}
      />
      <Typography
        text={name}
        size="body-md"
        font="grotesk"
        weight="book"
        color={textColor}
        className="opacity-50"
      />
    </div>
  )
}

export default TestimonialCard
