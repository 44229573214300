import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import TestimonialCard from "../../../V2/Cards/TestimonialCard"

export default function StoryblokTestimonialCard({
  blok,
}: Storyblok.BlokProps<Storyblok.TestimonialCard>) {
  return (
    <TestimonialCard
      image={blok.image.filename}
      name={blok.name}
      description={blok.description}
      textColor={blok.textColor}
      backgroundColor={blok.backgroundColor}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}
